import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8dfe863e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _e2ac054c = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _bfc784da = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _7e41dcf4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7097b9d6 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _7c976073 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ef1b5fa2 = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _16f0153a = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _4a82097c = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _7fe62f66 = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _dc17bf3a = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _4e8ed0b4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _8dfe863e,
    name: "about"
  }, {
    path: "/career",
    component: _e2ac054c,
    name: "career",
    children: [{
      path: ":position?",
      component: _bfc784da,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _7e41dcf4,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _7097b9d6,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _7c976073,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _ef1b5fa2,
    name: "selected-works"
  }, {
    path: "/services",
    component: _16f0153a,
    name: "services"
  }, {
    path: "/team",
    component: _4a82097c,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _7fe62f66,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _dc17bf3a,
    name: "team-member"
  }, {
    path: "/",
    component: _4e8ed0b4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
